import {AbstractRouter} from "../Router";
import {DailyReport} from "../DailyReport";

import {RealtimeReport} from "../RealtimeReport";
import {Properties} from "../../common/properties";


class FoodyRouter extends AbstractRouter {

    getConfigDicts() {
        let realtimeConfigDicts = this.getRealtimeConfigDicts();
        let dailyConfigDicts = this.getDailyConfigDicts();
        return [
            ...realtimeConfigDicts,
            ...dailyConfigDicts,
        ];
    }

    getRealtimeConfigDicts() {
        let realtimeConfigDicts = [
            {metric: 'net_gmv', reportLabel: 'Net GMV', reportClass: RealtimeReport},
            {metric: 'net_order', reportLabel: 'Net Order', reportClass: RealtimeReport},
        ];
        return realtimeConfigDicts.map(configDict => {
            return {
                frequency: Properties.frequencyBit.realtime,
                productGroup: 'shopeefood_api',
                ...configDict
            }
        });
    }

    getDailyConfigDicts() {
        let dailyConfigDicts = [
            {metric: 'net_gmv', reportLabel: 'Net GMV', reportClass: DailyReport},
            {metric: 'net_order', reportLabel: 'Net Order', reportClass: DailyReport},
        ];
        return dailyConfigDicts.map(configDict => {
            return {
                frequency: Properties.frequencyBit.daily,
                reportClass: DailyReport,
                productGroup: 'shopeefood_api',
                ...configDict
            }
        });
    }

    getMatchUrl() {
        return '/shopeefood';
    }
}

export {FoodyRouter};