import React, {Component} from 'react';
import _ from 'lodash';
import {Properties} from "../../common/properties";
import {UserMetaUtils} from "../../common/utils";
import {Section} from "../../components/SidebarSection";
import find from 'lodash/find'


class FoodySidebar extends Component {

    constructor(props) {
        super(props);
        this.state = {
            products: [],
        };
    }

    hasMetric(product, metric, frequencyBit) {
        return Boolean(this.props.userMeta.meta_data[product] &&
            UserMetaUtils.getMetaMetricKey(metric, frequencyBit) in this.props.userMeta.meta_data[product].metrics);
    }

    makeLinks(foodyConfig) {
        return _.filter([
            {config: foodyConfig, metric: 'net_order', frequencyBit: Properties.frequencyBit.realtime, label: 'Realtime Net Order', metricGroup: 'Order'},
            {config: foodyConfig, metric: 'net_order', frequencyBit: Properties.frequencyBit.daily, label: 'Net Order', metricGroup: 'Order'},
            {config: foodyConfig, metric: 'net_gmv', frequencyBit: Properties.frequencyBit.realtime, label: 'Realtime Net GMV', metricGroup: 'GMV'},
            {config: foodyConfig, metric: 'net_gmv', frequencyBit: Properties.frequencyBit.daily, label: 'Net GMV', metricGroup: 'GMV'},
        ], ({config, metric, frequencyBit}) => this.hasMetric(config.product, metric, frequencyBit));
    }

    render() {
        const foodyConfig = this.props.userMeta.data && find(this.props.userMeta.data, config => config.product === 'shopeefood')
        return (
            <React.Fragment>
                {foodyConfig && <Section key={'section' + foodyConfig.label}
                                         productType={'shopeefood'}
                                         links={this.makeLinks(foodyConfig)}
                                         label={foodyConfig.label}
                                         additionalData={this.props.additionalData}
                                />}
            </React.Fragment>
        )
    }
}

export {FoodySidebar};